@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	/* box-sizing: border-box; */
}

* {
	scroll-behavior: smooth;
}

.text-gradient {
	background: radial-gradient(
		64.18% 64.18% at 71.16% 35.69%,
		#def9fa 0.89%,
		#f1afbc 17.23%,
		#ec95a6 42.04%,
		#eaa0af 55.12%,
		#aaf0da 71.54%,
		#aaf0da 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.bg-discount-gradient {
	background: linear-gradient(125.17deg, #fbee8f 0%, #ffffff 100%);
}

.bg-yellow-gradient {
	background: linear-gradient(125.17deg, #f9f479 0%, #ffffff 100%);
}

.blue__gradient {
	background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
	filter: blur(123px);
}

.pink__gradient {
	background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
	filter: blur(900px);
}

.black__gradient {
	background: rgba(0, 0, 0, 0.6);
	filter: blur(750px);
}

a {
	text-decoration: none;
	color: #000;
}

.displaynumberoftabs {
	display: inline-block;
	font-size: 14px;
	justify-content: center;
	opacity: 1;
	color: rgb(255, 255, 255);
}

.donotdisplaynumberoftabs {
	display: inline-block;
	font-size: 14px;
	justify-content: center;
	opacity: 0;
	color: rgb(255, 255, 255);
}

body {
	font-family: "Poppins", sans-serif;
}

.btn {
	padding: 5px 10px;
	margin-left: 5px;
	border: 1px solid #000;
	border-radius: 5px;
	color: rgb(255, 255, 255);
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	text-align: center;
	appearance: button;
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	height: auto;
}

.btn:hover {
	transform: scale(0.98);
}

.changeonhover:hover {
	background-color: rgb(238, 223, 223);
	/* background-color: #FCF6DA; */
	transform: scale(0.96);
	-webkit-filter: drop-shadow(
		0 0 4px black
	); /* webkit only 
                     assuming the content is written in black */
	filter: drop-shadow(0 0 4px black); /* FF~35 */
}

.webkitfilter {
	transform: scale(0.96);
	-webkit-filter: drop-shadow(
		0 0 4px black
	); /* webkit only 
                     assuming the content is written in black */
	filter: drop-shadow(0 0 4px black); /* FF~35 */
}

.webkitfilter-white {
	transform: scale(0.96);
	-webkit-filter: drop-shadow(
		0 0 4px rgb(255, 255, 255)
	); /* webkit only 
                     assuming the content is written in black */
	filter: drop-shadow(0 0 4px rgb(255, 255, 255)); /* FF~35 */
}

/* .copybtn {
  background: rgb(47, 159, 41);
} */

.flex {
	display: flex;
	justify-self: center;
	text-align: center;
}

.googlebtn {
	display: flex;
	width: auto;
	padding: 7px;

	/* Yellow btn color */
	/* background: #ffe600;
  color: rgb(0, 0, 0); */
	/* Orange btn color */
	/* background: #f97800;
  color: rgb(255, 255, 255); */
	/* Blue btn color */
	background: #5892f1;
	color: rgb(255, 255, 255);
	border-radius: 30px;
	border: thin solid #888;
	box-shadow: 1px 1px 1px grey;
	font-size: 15px;
	font-weight: bold;
	font-family: "Roboto-Medium", sans-serif;
	cursor: pointer;
	align-items: center;
	opacity: 0.8;
}

.googlebtn:hover {
	background: #1c6ff3;
	color: rgb(255, 255, 255);
	opacity: 1;
	-webkit-filter: drop-shadow(
		0 0 4px black
	); /* webkit only 
                     assuming the content is written in black */
	filter: drop-shadow(0 0 4px black); /* FF~35 */
}

.googlebtncontainer {
	display: flex;
	justify-content: center;
	align-self: center;
	padding: 20px;
}

.googlelogo {
	display: flex;
	vertical-align: middle;
	width: 32px;
	height: 32px;
}

.googlelogowhitebox {
	display: flex;
	width: auto;
	height: auto;
	border-radius: 30px;
	background-color: white;
	margin-right: 7px;
	padding: 10px 10px 10px 10px;
}

ul {
	list-style: none;
}

i {
	padding-right: 7px;
}

.dropshadowfilter {
	opacity: 1;
	-webkit-filter: drop-shadow(
		0 0 4px black
	); /* webkit only 
                     assuming the content is written in black */
	filter: drop-shadow(0 0 5px #000000); /* FF~35 */
}

.refreshbtn {
	display: flex;
	justify-self: center;
	align-items: center;
	text-align: center;
	image-rendering: auto;
	image-rendering: crisp-edges;
	image-rendering: pixelated;
	cursor: pointer;
	opacity: 1;
}

.refreshbtn:hover {
	opacity: 5;
	-webkit-filter: drop-shadow(
		0 0 4px black
	); /* webkit only 
                     assuming the content is written in black */
	filter: drop-shadow(0 0 4px black); /* FF~35 */
}

.fc .fc-col-header-cell-cushion {
	display: inline-block;
	padding: 2px 4px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.fc {
	height: 800px;
}

/* Features.jsx slideshow*/
.slideshowDot {
	display: inline-block;
	height: 20px;
	width: 20px;
	border-radius: 50%;

	cursor: pointer;
	margin: 15px 7px 0px;

	background-color: #c4c4c4;
}

.slideshowDot.active {
	background-color: #6a0dad;
}
